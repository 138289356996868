<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card  shadow border-0">

        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <h1>Crear suscripción</h1>
          </div>

          <p class=" mt-0 mb-4">¿Deseas crear una nueva compañia o eres parte de una empresa existente?</p>

          <base-button block type="secondary" class=" mb-3" @click="MostrarNuevo = true">
            Crear Suscripcion
          </base-button>

          <!-- <a href="javascript:history.go(-1)" class="btn btn-default mb-4">Soy colaborador</a>-->
          <base-button block type="default" class=" mb-3" @click="EsColaborador = true">
            Soy colaborador
          </base-button>
          <modal :show.sync="EsColaborador"
                 gradient="info"
                 modal-classes="modal-danger modal-dialog-centered">
            <h6 slot="header" class="modal-title" id="modal-title-notification">Estas a un paso</h6>

            <div class="py-3 text-center">
              <i class="ni ni-support-16 ni-4x"></i>
              <h4 class="heading mt-4">¡Enhorabuena!</h4>
              <p>
                Comparte tu correo o telefono a tu empleador para que recibas una invitación para pertenecer a su compañia.
              </p>
            </div>

            <template slot="footer">

              <base-button type="link"
                           text-color="white"
                           class="ml-auto"
                           @click="EsColaborador = false">
                Entendido
              </base-button>
            </template>
          </modal>

        </div>
      </div>

    </div>
    <NuevoModal :Mostrar.sync="MostrarNuevo"
                v-on:guardado="OnGuardado"></NuevoModal>
  </div>
</template>

<script>
  import NuevoModal from './Nuevo.modal.vue';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
  import { extend } from "vee-validate";
  import { required, min, max_value, min_value } from "vee-validate/dist/rules";
  extend("required", required);
  extend("min", min);
  extend("max_value", max_value);
  extend("min_value", min_value);

  import { localize } from "vee-validate";

  localize({
    es: {
      messages: {
        // generic rule messages...
      },
      fields: {
        Etiqueta: {

          required: 'Nombre Comercial o Etiqueta para identificar su suscripción',

        },
        Contacto: {

          required: 'Nombre de la persona que fungirá como contacto principal',
          min: 'Debe ser mínimo de {length} caracteres'

        },
        Telefono: {

          required: 'Número teléfonico de la empresa',
          min: 'Debe ser mínimo de {length} caracteres'

        },
        NoEmpleados: {

          required: 'La cantidad de empleados se requiere para una optima configuración',
          min_value: 'Rengo de empleados no válido'

        },
        NoSucursales: {

          required: 'La cantidad de sucursales se requiere para una optima configuración',
          min_value: 'Rengo de sucursales no válido'

        },
      }
    }
  });

  export default {
    name: 'Suscripcion-Pregunta',
    data() {
      return {
        MostrarNuevo: false,


        EsColaborador: false,
        CrearSuscripcion: false,

        trabajando: false,
      }
    },
    components: {

      NuevoModal
    },
    //https://es.vuejs.org/v2/guide/instance.html
    created: function () {

    },
    mounted() {

    },
    methods: {
      OnGuardado(Datos) {
        SuscripcionesUsuarios.setSuscripcionID( Datos.suscripcionID);
        //localStorage.suscripcionID = Datos.suscripcionID;
        if (this.$route.query.from === undefined)
          this.$router.push('/');
        else
          this.$router.push(this.$route.query.from);
      },


    }

  }
</script>

<style scoped>
</style>
