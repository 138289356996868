<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         modalContentClasses="card"
         headerClasses="card-header">

    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">{{Categoria}}</h5>
      <h3 class="card-title">{{Titulo}}</h3>
      <span class="text-muted">{{Ayuda}}</span>
    </div>

    <template>

      <ValidationObserver v-slot="{ handleSubmit}">
        <form @submit.prevent="handleSubmit(nuevo)">
          <ValidationProvider name="NoombreEmpresa"
                              rules="required"
                              v-slot="{ passed, failed, errors }">
            <MazInput v-model="datos.etiqueta"
                      placeholder="Nombre de la empresa"
                      :autocomplete="autocompleteEtiqueta" :disabled="loading || cargando"
                      class="maz-mb-3" required :loading="loading || cargando" :size="size"
                      :error="errors.length>0"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              <template v-slot:icon-left>
                <i class="far fa-registered"></i>
              </template>
            </MazInput>
          </ValidationProvider>
          <div class="row mb-2 mx-0">
            <div class="col-4  mb-2 px-0">
              <MazInput v-model="datos.rfc"
                        placeholder="RFC / Tax ID" :size="size"
                        :disabled="loading || cargando" clearable
                        :loading="cargando" />
            </div>
            <div class="col-8  mb-2 px-0">
              <MazInput v-model="datos.razonSocial"
                        placeholder="Razon Social" :size="size"
                        :disabled="loading || cargando" clearable
                        :loading="cargando" />
            </div>
          </div>

          <MazInput v-model="datos.contacto" required class="mb-3"
                    placeholder="Contacto Principal" :size="size"
                    :disabled="loading || cargando"
                    :loading="cargando" >
            <template v-slot:icon-left>
              <i class="fas fa-user-tie"></i>
            </template>
          </MazInput>

          <div class="row mb-2 mx-0">
            <div class="col-12 mb-2 px-3">
              <MainTelefonoExtension v-model="datos.telefono" size="sm" placeholderExtension="Ext." placeholder="Teléfono de oficina"
                                     :cargando="cargando" />
            </div>
          </div>
          <div class="row mb-2 mx-0">
            <div class="col-6  mb-2 px-0">
              <ValidationProvider name="NoEmpleados"
                                  rules="required|min_value:1|max_value:1000000"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.noSucursales" required
                          placeholder="# Sucursales" :size="size"
                          :disabled="loading || cargando"
                          :loading="cargando"
                          :error="errors>0" type="number"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            <template v-slot:icon-left>
              <i class="fas fa-building"></i>
            </template>
          </MazInput>
              </ValidationProvider>
            </div>
            <div class="col-6  mb-2 px-0">
              <ValidationProvider name="NoEmpleados"
                                  rules="required|min_value:1|max_value:1000000"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.noEmpleados" required
                          placeholder="# Empleados" :size="size"
                          :disabled="loading || cargando"
                          :loading="cargando"
                          :error="errors>0" type="number"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]" >
            <template v-slot:icon-left>
              <i class="fas fa-address-book"></i>
            </template>
          </MazInput>
              </ValidationProvider>
            </div>
          </div>
          <MazInput v-model="datos.paginaWeb"
                    placeholder="Página Web, ej. www.eClock.com.mx" :size="size" type="url"
                    :disabled="loading || cargando" clearable
                    :loading="cargando" />


          <collapse :multiple-active="false" :active-index="-1" class="mb-0">
            <collapse-item title="Capturar Dirección">
              <MainDireccionComp v-model="datos.dDireccion" size="sm"
                                 :cargando="cargando" />
            </collapse-item>
          </collapse>
          <hr class="my-md-4" />
          <base-button v-if="!cargando" native-type="submit" slot="footer" type="primary" block>
            Registrar
          </base-button>



          <div class="text-muted text-center">

            <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
              {{ Erroneo }}
            </p>
            <div v-if="cargando" class="text-center mt-7">
              <b-spinner type="grow" label="cargando..."></b-spinner>
              Cargando...
            </div>
            <a href="#" class="d-block mt-2" @click="Cerrar()">
              <i class="fas fa-chevron-left"> </i> Cancelar
            </a>

          </div>
        </form>
      </ValidationObserver>
    </template>

  </modal>
</template>

<script>
  import Servicio from '@/api-base/suscripciones.service';
  import MainDireccionComp from '@/Vistas-base/Main/Direccion.comp.vue';
  import MainTelefonoExtension from '@/Vistas-base/Main/TelefonoExtension.comp.vue';
  import { Collapse, CollapseItem } from 'src/components';
  import Usuarios from '@/api-base/usuarios.service';
  export default {
    name: 'Suscripciones-Nuevo',
    components: {
      MainDireccionComp,
      Collapse,
      CollapseItem,
      MainTelefonoExtension
    },
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Suscripciones' },
      Titulo: { type: String, default: 'Nueva' },
      Ayuda: { type: String, default: 'Crea una cuenta donde tendrás todos tus empleados, dispositivos, turnos, otros administradores y empresas.' },

      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        size: 'sm',
        datos: {

          dDireccion: { pais: 'MX' },
          contacto: '',
          telefono: '',
        },

        Modal: false,
        cargando: false,

        Erroneo: ''
      }
    },
    computed: {
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },

    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() { this.Modal = this.Mostrar;
      Usuarios.getPerfilLocal((UsuarioPerfil) => {
        console.debug(UsuarioPerfil);
        if (this.datos.contacto== null || this.datos.contacto == '')
          this.datos.contacto = UsuarioPerfil.usuario.nombre;
        if (this.datos.telefono == null || this.datos.telefono == '') {

          this.datos.telefono = UsuarioPerfil.usuario.telefono;
        }
          
      });
    },
    created() {
    },
    methods: {
      nuevo() {
        Servicio.post(this, this.datos, (response) => {
          this.$emit('guardado', response.data);

          this.Cerrar();

        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
        });
      },
      Cerrar() {
        this.datos = {
          dDireccion: { pais: 'MX' },
          contacto: '',
          telefono: '',
        };
        this.Modal = false;
      },
    }
  }
</script>

<style scoped>
</style>
